.kinema-theme {
  font-family: 'RegolaPro', sans-serif;
}
.kinema-theme .shaka-bottom-controls {
  width: 100%;
  padding: 0;
  padding-bottom: 0;
  z-index: 1;
}
.kinema-theme .shaka-bottom-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.kinema-theme .shaka-ad-controls {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.kinema-theme .shaka-controls-button-panel {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  height: 60px;
  padding: 0 10px;
}
.kinema-theme .shaka-range-container {
  margin: 4px 10px 4px 10px;
  top: 0;
}
.kinema-theme .shaka-small-play-button {
  -webkit-box-ordinal-group: -2;
  -ms-flex-order: -3;
  order: -3;
}
.kinema-theme .shaka-mute-button {
  -webkit-box-ordinal-group: -1;
  -ms-flex-order: -2;
  order: -2;
}
.kinema-theme .shaka-controls-button-panel > * {
  margin: 0;
  padding: 3px 8px;
  color: #eee;
  height: 60px;
}
.kinema-theme .shaka-controls-button-panel > *:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
}
.kinema-theme .shaka-controls-button-panel > *:hover {
  color: #fff;
}
.kinema-theme .shaka-controls-button-panel .shaka-volume-bar-container {
  position: relative;
  z-index: 10;
  left: -1px;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  opacity: 0;
  width: 0px;
  -webkit-transition: width 0.2s cubic-bezier(0.4, 0, 1, 1);
  height: 3px;
  transition: width 0.2s cubic-bezier(0.4, 0, 1, 1);
  padding: 0;
}
.kinema-theme .shaka-controls-button-panel .shaka-volume-bar-container:hover,
.kinema-theme .shaka-controls-button-panel .shaka-volume-bar-container:focus {
  display: block;
  width: 50px;
  opacity: 1;
  padding: 0 6px;
}
.kinema-theme .shaka-mute-button:hover + div {
  opacity: 1;
  width: 50px;
  padding: 0 6px;
}
.kinema-theme .shaka-current-time {
  padding: 0 10px;
  padding-top: 3px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  font-variant-numeric: tabular-nums;
}
.kinema-theme .shaka-seek-bar-container {
  height: 3px;
  position: relative;
  top: -1px;
  border-radius: 0;
  margin-bottom: 0;
}
.kinema-theme .shaka-seek-bar-container .shaka-range-element {
  opacity: 0;
}
.kinema-theme .shaka-seek-bar-container:hover {
  height: 5px;
  top: 0;
  cursor: pointer;
}
.kinema-theme .shaka-seek-bar-container:hover .shaka-range-element {
  opacity: 1;
  cursor: pointer;
}
.kinema-theme
  .shaka-seek-bar-container
  input[type='range']::-webkit-slider-thumb {
  background: #3e36a2;
  cursor: pointer;
}
.kinema-theme .shaka-seek-bar-container input[type='range']::-moz-range-thumb {
  background: #3e36a2;
  cursor: pointer;
}
.kinema-theme .shaka-seek-bar-container input[type='range']::-ms-thumb {
  background: #3e36a2;
  cursor: pointer;
}
.kinema-theme * {
  font-family: 'Gilroy', sans-serif;
}
.kinema-theme .material-icons-round {
  font-family: 'Material Icons Sharp';
  font-size: 40px;
}
.kinema-theme .shaka-overflow-menu,
.kinema-theme .shaka-settings-menu {
  border-radius: 2px;
  background: rgba(28, 28, 28, 0.9);
  text-shadow: 0 0 2px rgb(0 0 0%);
  -webkit-transition: opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.1s cubic-bezier(0, 0, 0.2, 1);
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  right: 10px;
  bottom: 75px;
  padding: 8px 0;
  min-width: 250px;
}
.kinema-theme .shaka-settings-menu {
  padding: 0 0 8px 0;
}
.kinema-theme .shaka-settings-menu button {
  font-size: 12px;
}
.kinema-theme .shaka-settings-menu button span {
  margin-left: 33px;
  font-size: 15px;
}
.kinema-theme .shaka-settings-menu button[aria-selected='true'] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.kinema-theme .shaka-settings-menu button[aria-selected='true'] span {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  margin-left: 0;
}
.kinema-theme .shaka-settings-menu button[aria-selected='true'] i {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  font-size: 18px;
  padding-left: 5px;
}
.kinema-theme .shaka-overflow-menu button {
  padding: 0;
}
.kinema-theme .shaka-overflow-menu button i {
  display: none;
}
.kinema-theme .shaka-overflow-menu button .shaka-overflow-button-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  outline: none;
  height: 40px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
.kinema-theme .shaka-overflow-menu button .shaka-overflow-button-label span {
  -ms-flex-negative: initial;
  flex-shrink: initial;
  padding-left: 15px;
  font-size: 15px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.kinema-theme .shaka-overflow-menu span + span {
  color: #fff;
  font-weight: 400 !important;
  font-size: 15px !important;
  padding-right: 8px;
  padding-left: 0 !important;
}
.kinema-theme .shaka-overflow-menu span + span:after {
  content: 'navigate_next';
  font-family: 'Material Icons Sharp';
  font-weight: 400;
  font-size: 20px;
}
.kinema-theme .shaka-overflow-menu .shaka-pip-button span + span {
  padding-right: 15px !important;
}
.kinema-theme .shaka-overflow-menu .shaka-pip-button span + span:after {
  content: '';
}
.kinema-theme .shaka-back-to-overflow-button {
  padding: 8px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 12px;
  color: #eee;
  height: 40px;
}
.kinema-theme .shaka-back-to-overflow-button .material-icons-round {
  padding-right: 10px;
  font-size: 20px;
}
.kinema-theme .shaka-back-to-overflow-button span {
  margin-left: 3px !important;
}
.kinema-theme .shaka-overflow-menu button:hover,
.kinema-theme .shaka-settings-menu button:hover {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.kinema-theme .shaka-overflow-menu button:hover label,
.kinema-theme .shaka-settings-menu button:hover label {
  cursor: pointer;
}
.kinema-theme .shaka-overflow-menu button:focus,
.kinema-theme .shaka-settings-menu button:focus {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  outline: none;
}
.kinema-theme .shaka-overflow-menu button,
.kinema-theme .shaka-settings-menu button {
  color: #eee;
}
.kinema-theme .shaka-captions-off {
  color: #bfbfbf;
}
.kinema-theme .shaka-overflow-menu-button {
  content: 'settings';
  font-size: 30px;
  margin-right: 5px;
}
.kinema-theme .shaka-fullscreen-button:hover {
  font-size: 40px;
  -webkit-transition: font-size 0.1s cubic-bezier(0, 0, 0.2, 1);
  transition: font-size 0.1s cubic-bezier(0, 0, 0.2, 1);
}
.kinema-theme:fullscreen
  .shaka-text-container
  > div
  > span:not(.shaka-text-wrapper) {
  /* In fullscreen mode, the text displayer's font size should be relative to
   * the either window height or width (whichever is smaller), instead of a
   * fixed size. */
  font-size: 4.4vmin;
}
