/**
 * @license
 * MyFonts Webfont Build ID 3780559, 2019-07-02T15:53:53-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Gilroy-Thin by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/thin/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-UltraLightItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/ultra-light-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-ThinItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/thin-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-SemiBoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-SemiBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-UltraLight by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/ultra-light/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Regular by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-RegularItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-MediumItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/medium-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Medium by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/medium/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-LightItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/light-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Light by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/light/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-HeavyItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/heavy-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Heavy by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/heavy/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-ExtraBoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/extra-bold-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-ExtraBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/extra-bold/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-BoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Bold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-BlackItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/black-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 * 
 * Webfont: Gilroy-Black by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/black/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * 
 * 
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
/* @import url('//hello.myfonts.net/count/39afcf'); */

/* Gilroy-Thin */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_0_0.eot');
  src: url('gilroy/39AFCF_0_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_0_0.woff2') format('woff2'),
    url('gilroy/39AFCF_0_0.woff') format('woff'),
    url('gilroy/39AFCF_0_0.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* Gilroy-UltraLightItalic */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_1_0.eot');
  src: url('gilroy/39AFCF_1_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_1_0.woff2') format('woff2'),
    url('gilroy/39AFCF_1_0.woff') format('woff'),
    url('gilroy/39AFCF_1_0.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/* Gilroy-ThinItalic */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_2_0.eot');
  src: url('gilroy/39AFCF_2_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_2_0.woff2') format('woff2'),
    url('gilroy/39AFCF_2_0.woff') format('woff'),
    url('gilroy/39AFCF_2_0.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Gilroy-SemiBoldItalic */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_3_0.eot');
  src: url('gilroy/39AFCF_3_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_3_0.woff2') format('woff2'),
    url('gilroy/39AFCF_3_0.woff') format('woff'),
    url('gilroy/39AFCF_3_0.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

/* Gilroy-SemiBold */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_4_0.eot');
  src: url('gilroy/39AFCF_4_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_4_0.woff2') format('woff2'),
    url('gilroy/39AFCF_4_0.woff') format('woff'),
    url('gilroy/39AFCF_4_0.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* Gilroy-UltraLight */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_5_0.eot');
  src: url('gilroy/39AFCF_5_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_5_0.woff2') format('woff2'),
    url('gilroy/39AFCF_5_0.woff') format('woff'),
    url('gilroy/39AFCF_5_0.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* Gilroy-Regular */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_6_0.eot');
  src: url('gilroy/39AFCF_6_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_6_0.woff2') format('woff2'),
    url('gilroy/39AFCF_6_0.woff') format('woff'),
    url('gilroy/39AFCF_6_0.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Gilroy-RegularItalic */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_7_0.eot');
  src: url('gilroy/39AFCF_7_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_7_0.woff2') format('woff2'),
    url('gilroy/39AFCF_7_0.woff') format('woff'),
    url('gilroy/39AFCF_7_0.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* Gilroy-MediumItalic */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_8_0.eot');
  src: url('gilroy/39AFCF_8_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_8_0.woff2') format('woff2'),
    url('gilroy/39AFCF_8_0.woff') format('woff'),
    url('gilroy/39AFCF_8_0.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* Gilroy-Medium */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_9_0.eot');
  src: url('gilroy/39AFCF_9_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_9_0.woff2') format('woff2'),
    url('gilroy/39AFCF_9_0.woff') format('woff'),
    url('gilroy/39AFCF_9_0.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* Gilroy-LightItalic */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_A_0.eot');
  src: url('gilroy/39AFCF_A_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_A_0.woff2') format('woff2'),
    url('gilroy/39AFCF_A_0.woff') format('woff'),
    url('gilroy/39AFCF_A_0.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

/* Gilroy-Light */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_B_0.eot');
  src: url('gilroy/39AFCF_B_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_B_0.woff2') format('woff2'),
    url('gilroy/39AFCF_B_0.woff') format('woff'),
    url('gilroy/39AFCF_B_0.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/* Gilroy-HeavyItalic */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_C_0.eot');
  src: url('gilroy/39AFCF_C_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_C_0.woff2') format('woff2'),
    url('gilroy/39AFCF_C_0.woff') format('woff'),
    url('gilroy/39AFCF_C_0.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

/* Gilroy-Heavy */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_D_0.eot');
  src: url('gilroy/39AFCF_D_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_D_0.woff2') format('woff2'),
    url('gilroy/39AFCF_D_0.woff') format('woff'),
    url('gilroy/39AFCF_D_0.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

/* Gilroy-ExtraBoldItalic */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_E_0.eot');
  src: url('gilroy/39AFCF_E_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_E_0.woff2') format('woff2'),
    url('gilroy/39AFCF_E_0.woff') format('woff'),
    url('gilroy/39AFCF_E_0.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

/* Gilroy-ExtraBold */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_F_0.eot');
  src: url('gilroy/39AFCF_F_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_F_0.woff2') format('woff2'),
    url('gilroy/39AFCF_F_0.woff') format('woff'),
    url('gilroy/39AFCF_F_0.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* Gilroy-BoldItalic */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_10_0.eot');
  src: url('gilroy/39AFCF_10_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_10_0.woff2') format('woff2'),
    url('gilroy/39AFCF_10_0.woff') format('woff'),
    url('gilroy/39AFCF_10_0.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* Gilroy-Bold */
@font-face {
  font-family: 'Gilroy';
  src: url('gilroy/39AFCF_11_0.eot');
  src: url('gilroy/39AFCF_11_0.eot?#iefix') format('embedded-opentype'),
    url('gilroy/39AFCF_11_0.woff2') format('woff2'),
    url('gilroy/39AFCF_11_0.woff') format('woff'),
    url('gilroy/39AFCF_11_0.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
