/* RegolaPro-Book */
@font-face {
  font-family: 'RegolaPro';
  src: url('regola/RegolaPro-Book.eot');
  src: url('regola/RegolaPro-Book.eot') format('embedded-opentype'),
    url('regola/RegolaPro-Book.woff2') format('woff2'),
    url('regola/RegolaPro-Book.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

/* RegolaPro-BookItalic */
@font-face {
  font-family: 'RegolaPro';
  src: url('regola/RegolaPro-BookOblique.eot');
  src: url('regola/RegolaPro-BookOblique.eot') format('embedded-opentype'),
    url('regola/RegolaPro-BookOblique.woff2') format('woff2'),
    url('regola/RegolaPro-BookOblique.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

/* RegolaPro-Regular */
@font-face {
  font-family: 'RegolaPro';
  src: url('regola/RegolaPro-Regular.eot');
  src: url('regola/RegolaPro-Regular.eot') format('embedded-opentype'),
    url('regola/RegolaPro-Regular.woff2') format('woff2'),
    url('regola/RegolaPro-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

/* RegolaPro-RegularItalic */
@font-face {
  font-family: 'RegolaPro';
  src: url('regola/RegolaPro-RegularOblique.eot');
  src: url('regola/RegolaPro-RegularOblique.eot') format('embedded-opentype'),
    url('regola/RegolaPro-RegularOblique.woff2') format('woff2'),
    url('regola/RegolaPro-RegularOblique.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

/* RegolaPro-Medium */
@font-face {
  font-family: 'RegolaPro';
  src: url('regola/RegolaPro-Medium.eot');
  src: url('regola/RegolaPro-Medium.eot') format('embedded-opentype'),
    url('regola/RegolaPro-Medium.woff2') format('woff2'),
    url('regola/RegolaPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

/* RegolaPro-MediumItalic */
@font-face {
  font-family: 'RegolaPro';
  src: url('regola/RegolaPro-MediumOblique.eot');
  src: url('regola/RegolaPro-MediumOblique.eot?#iefix')
      format('embedded-opentype'),
    url('regola/RegolaPro-MediumOblique.woff2') format('woff2'),
    url('regola/RegolaPro-MediumOblique.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

/* RegolaPro-Bold */
@font-face {
  font-family: 'RegolaPro';
  src: url('regola/RegolaPro-Bold.eot');
  src: url('regola/RegolaPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('regola/RegolaPro-Bold.woff2') format('woff2'),
    url('regola/RegolaPro-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

/* RegolaPro-BoldItalic */
@font-face {
  font-family: 'RegolaPro';
  src: url('regola/RegolaPro-BoldOblique.eot');
  src: url('regola/RegolaPro-BoldOblique.eot?#iefix')
      format('embedded-opentype'),
    url('regola/RegolaPro-BoldOblique.woff2') format('woff2'),
    url('regola/RegolaPro-BoldOblique.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
